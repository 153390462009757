<script lang="ts" setup>
import type { Social, SocialMethod } from "@/types";

const { t } = useT();

withDefaults(
	defineProps<{
		socials: Social[];
		theme?: "dark" | "light";
		locationTid?: string;
		appleEnabled?: boolean;
		hideName?: boolean;
	}>(),
	{ theme: "light" }
);

const emit = defineEmits<{ (event: "clickSocial", payload: { name: SocialMethod; href?: string }): void }>();

const route = useRoute();
const storePromoOffer = () => {
	const { promoOffer } = route.query;
	if (promoOffer && !Array.isArray(promoOffer)) {
		localStorage.setItem("promoOffer", promoOffer);
	}
};

const handleButtonClick = ({ name, href }: { name: SocialMethod; href?: string }) => {
	storePromoOffer();

	emit("clickSocial", { name, href });
};
</script>

<template>
	<div :class="['socials', theme]">
		<AButton
			v-for="social in socials"
			:key="social.id"
			:class="`social social-${social.id}`"
			:data-tid="locationTid ? `${locationTid}-${social.name.toLowerCase()}-btn` : null"
			type="button"
			@click.stop="handleButtonClick({ name: social.name, href: social.href })"
		>
			<NuxtIcon :name="`24/${social.icon}`" filled />
			<AText v-if="theme === 'light'" :modifiers="['uppercase']">{{ t("With") }} {{ social.name }}</AText>
		</AButton>
		<AButton
			v-if="appleEnabled"
			class="social social-apple"
			type="button"
			@click.stop="handleButtonClick({ name: 'apple' })"
		>
			<NuxtIcon name="24/apple" filled />
			<AText v-if="theme === 'light'" :modifiers="['uppercase']">{{ t("With") }} {{ t("authBtn.apple") }}</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.socials {
	display: flex;
	gap: 8px;

	> * {
		flex: 1;
	}

	&.light {
		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}
	}
}

.social {
	display: inline-flex;
	gap: 4px;
	width: 100%;

	&:deep(svg) {
		transform: translateY(-1px);
		margin: 0;
		font-size: 24px;
	}

	&.social-fb:deep(path) {
		fill: var(--secondary-50);
	}

	&.social-apple:deep(path) {
		fill: var(--neutral-100);
	}
}

.light .social {
	padding: 12px 16px;
	background: var(--neutral-100);
	border-color: currentColor;
	color: var(--secondary-50);
	transition: background 0.3s;
	position: relative;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition:
			background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1),
			opacity 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
	}

	&:hover::before {
		background-color: currentColor;
		opacity: 0.1;
	}
}

.dark .social {
	padding: 13px 16px;
	border: 1px solid var(--secondary-50);
	background: var(--secondary-15);

	&:hover {
		background: var(--secondary-25);
	}
}
</style>
